import React from 'react';
import './CreditSchemaTable.scss';

const schemaTable = [
    { feature: "Audio Transkrip", minUsage: "1 menit", creditCut: 1660 },
    { feature: "Audio Translasi", minUsage: "1 menit", creditCut: 4630 },
    { feature: "Live Transkrip", minUsage: "1 menit", creditCut: 8330 },
    { feature: "Diarisasi", minUsage: "1 menit", creditCut: 320 },
    { feature: "Identifikasi", minUsage: "1 menit", creditCut: 150 },
    { feature: "Registrasi Pembicara", minUsage: "1 Pembicara", creditCut: 6350 },
    { feature: "Code-Mixing", minUsage: "1 menit", creditCut: 5560 },
    { feature: "Resume", minUsage: "1 menit", creditCut: 1110 },
    { feature: "Meeting Transkripsi", minUsage: "1 minute", creditCut: 10000 },
    { feature: "Meeting Code Switching/Mixing", minUsage: "1 minute", creditCut: 13900 },
    { feature: "Meeting Translasi", minUsage: "1 minute", creditCut: 12930 },
];

const CreditSchemaTable = () => (
    <div className="table-container">
        <table className="schema-table">
            <thead>
                <tr>
                    <th>Fitur</th>
                    <th>Minimal Pemakaian</th>
                    <th>Potongan Kredit</th>
                </tr>
            </thead>
            <tbody>
                {schemaTable.map((row, index) => (
                    <tr key={index}>
                        <td>{row.feature}</td>
                        <td>{row.minUsage}</td>
                        <td>{new Intl.NumberFormat('id-ID').format(row.creditCut)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>
);

const IllustrationCard = () => (
    <div className="illustration-card">
        <h2 className="title">Contoh</h2>
        <p className="description">
            
Jika Anda mengunggah file audio untuk ditranskripsi dengan tambahan fitur diarisasi dan identifikasi, 
dan durasi audionya <b>1 jam</b>, jumlah kredit yang akan digunakan adalah:
        </p>
        <div className="formula">
            <pre>
                TPK = DD * (UT + DR + ID) <br />
                = 1 * 3600 / 60 * (1.660 + 320 + 150) <br />
                = 60 * 2.100 <br />
                = 127.800 Kredit
            </pre>
        </div>
        <div className="notes">
            <b>Keterangan:</b>
            <ul>
                <li>TPK = Total Potongan Kredit</li>
                <li>DD = Detik Durasi / 60</li>
                <li>UT = Audio Transkrip (1.660 kredit)</li>
                <li>DR = Diarisasi (320 kredit)</li>
                <li>ID = Identifikasi (150 kredit)</li>
            </ul>
        </div>
    </div>
);

const CreditSchema = () => (
    <div className="credit-schema-container">
        <h2 className="main-title">Skema Pemotongan Kredit</h2>
        <div className="content-grid">
            <CreditSchemaTable />
            <IllustrationCard />
        </div>
    </div>
);

export default CreditSchema;
