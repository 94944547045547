import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './PricePackage.scss';

const availableFeatures = [
    "Audio Transkrip",
    "Audio Translasi",
    "Live Transkrip",
    "Code-Mixing",
    "Diarisasi",
    "Registrasi Pembicara",
    "Identifikasi Pembicara",
    "Summary & Action Plan",
    "Transkripsi YouTube",
    "Word Cloud",
    "Download Audio",
    "Unduh Transkrip (PDF, WORD, SRT, dll)",
    "Meeting Transkripsi",
    "Meeting Code Switching/Mixing",
    "Meeting Translasi"
];

const comingSoonFeatures = ["Zoom", "Gmeet", "Microsoft Teams"];

const platforms = [
    {
        name: 'Google Meet',
        logo: '/icon/gmeet.svg'
    },
    {
        name: 'Zoom',
        logo: '/icon/zoom.svg'
    },
    {
        name: 'Microsoft Teams',
        logo: '/icon/teams.svg'
    }
];

const Features = () => {
    return (
        <div className="features-container bg-white">
            <div className="features-card">
                {/* Judul */}
                <h2 className="features-title">
                    Bebas Akses Semua Fitur Unggulan
                </h2>

                {/* List Fitur */}
                <div className="features-grid">
                    {availableFeatures.map((feature, index) => (
                        <div key={index} className="feature-item">
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                className="feature-icon"
                            />
                            <span className="feature-text">{feature}</span>
                        </div>
                    ))}
                </div>

                <hr className="divider" />

                <div className="rounded d-flex flex-column align-items-center justify-content-center">
                    {/* Title */}
                    <h2 className="text-white fw-bold display-5 font-weight-bold">
                        Telah Hadir
                    </h2>

                    {/* Platforms row - changed to horizontal */}
                    <div className="d-flex flex-row align-items-center">
                        {platforms.map((platform, index) => (
                            <div key={index} className="d-flex align-items-center mx-1">
                                <img
                                    src={platform.logo}
                                    alt={platform.name}
                                    className="img-fluid"
                                    style={{ width: '150px', height: '64px', objectFit: 'contain' }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;
