import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';
import BillingStepHeader from '../BillingStepHeader/BillingStepHeader';
import store, { history } from '../../store';
import {
    fetchServiceList,
    fetchServicePackage,
    updateServicePackage,
    reqServicePackage
} from '../../reducers/ServiceReducers';
import { updateContinueToCheckoutFlag } from '../../reducers/BillingReducers';

import "./BilingPackage.scss"

function BillingPackage(props) {
    const { landing } = props;
    const dispatch = useDispatch();
    const ITEMS_PER_PAGE = 4;

    const { id_token } = useSelector(state => state.UserManagementReducers);
    const { service_package, service_list } = useSelector(state => state.ServiceReducers);

    const [currentPage, setCurrentPage] = useState(0);
    const [idService, setIdService] = useState(null);
    const [serviceName, setServiceName] = useState("");

    // Sort packages by price and add Custom package at the end
    const sortedPackages = React.useMemo(() => {
        if (!service_package || service_package.length === 0) return [];

        const regularPackages = service_package
            .filter(pkg => pkg.name !== "Free")
            .sort((a, b) => {
                // Use discounted price if available, otherwise use regular price
                const priceA = a.discount ? parseInt(a.discount) : parseInt(a.price);
                const priceB = b.discount ? parseInt(b.discount) : parseInt(b.price);
                return priceA - priceB;
            });

        // Create custom package object
        const customPackage = {
            id_package: 'custom',
            name: 'Custom',
            price: 0,
            discount: 0,
            minimal: 0,
            unit_name: 'Custom',
            descriptions: { id: [] }
        };

        return [...regularPackages, customPackage];
    }, [service_package]);

    const totalPages = Math.ceil(sortedPackages.length / ITEMS_PER_PAGE);

    const currentPackages = React.useMemo(() => {
        const start = currentPage * ITEMS_PER_PAGE;
        const end = start + ITEMS_PER_PAGE;
        return sortedPackages.slice(start, end);
    }, [currentPage, sortedPackages]);

    // Component cleanup
    useEffect(() => {
        return () => {
            dispatch(reqServicePackage([]));
        };
    }, []);

    // Handle initial service setup based on URL
    useEffect(() => {
        if (landing) {
            let service_name = history.location.pathname.replace('/', '');

            if (service_name.indexOf("notula") > -1) {
                service_name = "notula";
            } else if (service_name.indexOf("notulite") > -1) {
                service_name = "notulite";
            } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1 || service_name === "") {
                service_name = "dikte.in";
            }

            setServiceName(service_name);
        } else {
            let id_service = parseInt(props.match.params.id_service);
            setIdService(id_service);
        }
    }, [landing, props.match]);

    // Handle service list change
    useEffect(() => {
        if (!Array.isArray(service_list)) return;

        if (landing) {
            const index = service_list.findIndex(s => s.name.toLowerCase() === serviceName.toLowerCase());
            if (index > -1) {
                setIdService(service_list[index].id_service);
            }
        } else {
            const index = service_list.findIndex(s => s.id_service === idService);
            if (index > -1) {
                let service_name = service_list[index].name;
                if (service_name.indexOf("notula") > -1) {
                    service_name = "notula";
                } else if (service_name.indexOf("notulite") > -1) {
                    service_name = "notulite";
                } else if (service_name.indexOf("diktein") > -1 || service_name.indexOf("dikte.in") > -1) {
                    service_name = "dikte.in";
                }
                setServiceName(service_name);
            }
        }
    }, [service_list, landing, serviceName, idService]);

    // Fetch service list
    useEffect(() => {
        store.dispatch(fetchServiceList(id_token))
            .then(() => { })
            .catch(() => { });
    }, [idService, serviceName]);

    // Fetch service package when idService changes
    useEffect(() => {
        if (idService !== null) {
            store.dispatch(fetchServicePackage(idService))
                .then(() => { })
                .catch(() => { });
        }
    }, [idService]);

    return (
        <div
            id="biling-menu-container"
            className="pt-3 pr-4 pl-4 rounded-lg"
            style={{ backgroundColor: "#E7F0FF" }}
        >
            <h2
                className="text-center mb-5 font-weight-bold display-4"
                style={{ color: "#4087EE" }}
            >
                Paket Harga
            </h2>

            {!landing && <BillingStepHeader currentStep={1} totalStep={5} />}

            <Row>
                {!service_package || service_package.length === 0 ? (
                    <Loading />
                ) : (
                    currentPackages.map((packages, i) => (
                        <Col
                            key={i}
                            lg={3}
                            md={6}
                            sm={12}
                            className="d-flex justify-content-center mb-4"
                        >
                            <CardBillingPackage
                                key={i}
                                id_token={id_token}
                                id_package={packages.id_package}
                                title={packages.name}
                                services={packages.descriptions?.id || []}
                                price={parseInt(packages.price)}
                                discount={parseInt(packages.discount)}
                                minimal={parseInt(packages.minimal)}
                                unit={packages.unit_name !== null && packages.unit_name !== undefined ? packages.unit_name : "Unit"}
                                selected_packages={packages}
                                single={service_package.length > 1 || !landing ? false : true}
                            />
                        </Col>
                    ))
                )}
            </Row>

            {sortedPackages.length > ITEMS_PER_PAGE && (
                <div className="d-flex justify-content-center mt-4 mb-3">
                    <Button
                        onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
                        disabled={currentPage === 0}
                        className="mr-2 rounded-circle"
                        variant="outline-primary"
                        style={{ width: '40px', height: '40px', padding: '0' }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <Button
                        onClick={() => setCurrentPage(prev => Math.min(totalPages - 1, prev + 1))}
                        disabled={currentPage === totalPages - 1}
                        className="rounded-circle"
                        variant="outline-primary"
                        style={{ width: '40px', height: '40px', padding: '0' }}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </div>
            )}
        </div>
    );
}

class CardBillingPackage extends React.Component {
    redirectToUrl = async () => {
        await store.dispatch(updateContinueToCheckoutFlag(true));
        await store.dispatch(updateServicePackage(this.props.selected_packages));

        if (this.props.id_token === null || this.props.id_token === '') {
            history.push("/login");
        } else {
            history.push("/cart");
        }
    };

    priceFormat = (value) => {
        return new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(Math.floor(value / 100) * 100);
    };

    render() {
        const {
            title,
            price,
            unit,
            services,
            discount,
            minimal
        } = this.props;

        // Parse services properly
        const parsedServices = services && services.length > 0
            ? services[0].split(/[;]+/).map(item => item.trim())
            : [];

        // Get validity period if it exists
        const validityPeriod = services && services.length > 1
            ? services[1].trim()
            : null;

        return (
            <div className="card shadow-lg border-0 rounded-lg p-4 h-100 w-100">
                {title === 'Custom' ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div className="d-flex flex-column align-items-center text-primary">
                            <h2 className="font-weight-bold mb-2">{title}</h2>
                            <div className="text-muted mb-4">Paket Terbaik untuk Bisnis Anda</div>
                            <a
                                href={`${window._link_?.contact}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary text-white font-weight-semibold px-4 py-2 rounded-pill"
                                style={{ background: 'linear-gradient(to right, #76AFFF, #3D81E1)', border: 'none' }}
                            >
                                Hubungi Kami
                            </a>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex flex-column h-100">
                        {unit.toLowerCase().includes("custom") ? null : discount !== 0 && (
                            <div
                                className="w-100 text-white py-2 text-center rounded mb-3"
                                style={{ backgroundColor: "#4087EE" }}
                            >
                                Harga diskon!
                            </div>
                        )}

                        <div className="flex-grow-1">
                            <h3 className="font-weight-bold mb-2">{title}</h3>
                            
                            {unit.toLowerCase().includes("custom") ? null : discount !== 0 ? (
                                <>
                                    <div className="price mb-2">
                                        <span className="h3 font-weight-bold">
                                            {this.priceFormat(discount)}
                                        </span>
                                        <span className="text-secondary medium">
                                            {" "}/ {unit.replace("_callus", "")}
                                        </span>
                                    </div>
                                    <div className="text-muted mb-3">
                                        <span className="price-stroke">
                                            {this.priceFormat(price)}
                                        </span>
                                        <span> (Harga Normal)</span>
                                    </div>
                                </>
                            ) : (
                                <div className="price mb-2">
                                    <span className="h3 font-weight-bold">
                                        {this.priceFormat(price * minimal)}
                                    </span>
                                    <span className="text-secondary medium">
                                        {" "}/ {unit.replace("_callus", "")}
                                    </span>
                                </div>
                            )}
                            {validityPeriod && (
                                <p style={{ color: 'orange', fontSize: '15px' }}>
                                    {validityPeriod}
                                </p>
                            )}

                            <ListGroup className="mb-4">
                                <div
                                    className="font-weight-bold mb-2"
                                    style={{ color: "#3372CA", fontSize: "18px" }}
                                >
                                    {`Senilai:`} {parsedServices?.[0]?.split(';')?.[0]?.trim()}
                                </div>

                                {parsedServices.slice(1).map((value, i) => (
                                    <ListGroup.Item
                                        key={i}
                                        className="border-0 d-flex align-items-start bg-transparent px-0 py-1"
                                    >
                                        <div className="d-flex align-items-center">
                                            <FontAwesomeIcon icon={faCheck} className="mr-2 text-primary" />
                                            <span className="text-sm">
                                                {i >= 1 ? `Atau ${value}` : value}
                                            </span>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </div>

                        <div className="mt-auto pt-3">
                            <Button
                                onClick={this.redirectToUrl}
                                className="btn-block rounded-pill font-weight-bold py-2"
                                style={{
                                    background: 'linear-gradient(to right, #76AFFF, #3D81E1)',
                                    border: 'none'
                                }}
                            >
                                Beli Sekarang
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default BillingPackage;